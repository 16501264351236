import { useContentfulLocale } from 'hooks/useContentfulLocale';
import { Container, Section } from 'components/Container';
import { PricingCards } from 'components/PricingCards';

import type { FC } from 'react';

interface PricingBlockProps {
  pricingNote?: string;
  pricingDiscountPercentage?: number;
}

export const PricingBlock: FC<PricingBlockProps> = ({ pricingNote, pricingDiscountPercentage }) => {
  const { priceIncludesTax } = useContentfulLocale();

  return (
    <Container manageLayout={false} padding={15}>
      <Section center>
        <h2>Plans & Pricing</h2>
        <p>
          Start by tracking up to 10 holdings for <strong>free</strong>! Then upgrade to a premium
          plan at any time to track additional holdings or portfolios, and unlock advanced features.{' '}
          {priceIncludesTax && (
            <strong>And as a bonus, your Sharesight subscription may be tax deductible. *</strong>
          )}
        </p>
        <br />
      </Section>

      <PricingCards pricingDiscountPercentage={pricingDiscountPercentage} />

      {pricingNote && <p>{pricingNote}</p>}
    </Container>
  );
};
