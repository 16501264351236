import { useMemo } from 'react';
import { useLocalizationContext } from '@sharesight/gatsby-plugin-sharesight-localization';
import { graphql, useStaticQuery } from 'gatsby';
import { sortBy } from 'lodash';

import type { ContentfulSectionsGraphQLResponse } from './types';

const ContentfulSectionsQuery = graphql`
  query ContentfulSections {
    allContentfulSection {
      nodes {
        localeLang: node_locale
        sectionIdentifier
        header
        textContent {
          raw
        }
        image {
          file {
            url
            fileName
            contentType
          }
          gatsbyImageData
          title
        }
      }
    }
  }
`;

/** Returns all sections or sections that start with the matcher from the Contentful Gatsby space for you to filter/find from there */

export const useContentfulSections = (identifierStartsWith?: string) => {
  const { allContentfulSection }: ContentfulSectionsGraphQLResponse =
    useStaticQuery(ContentfulSectionsQuery);

  const { currentLocale } = useLocalizationContext();

  const filteredSections = useMemo(() => {
    const localisedSections = allContentfulSection.nodes.filter(
      section => section.localeLang === currentLocale.lang
    );

    const sortedSections = sortBy(localisedSections, 'sectionIdentifier');

    if (!identifierStartsWith) return sortedSections;

    return sortedSections.filter(section =>
      section.sectionIdentifier.startsWith(identifierStartsWith)
    );
  }, [identifierStartsWith, allContentfulSection.nodes, currentLocale.lang]);

  if (!filteredSections || filteredSections.length === 0) {
    throw new Error(`Could not find any Contentful Sections`);
  }

  return filteredSections;
};
